import React from "react"
import { Button } from "../components/Button"
import { Image } from "../components/Core"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "../css/pages/w4w.scss"

const W4W = () => {
  const image = name =>
    `https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/${name}`
  return (
    <Layout pageTitle="wisdom-page" language={"en"}>
      <SEO
        lang="en"
        title="Learn about our Wisdom For Wisdom program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />

      <div className="w4w-23">
        <div className="w4w-23__container">
          <div className="w4w-23__hero">
            <img
              className="w4w-23__hero--logo"
              src={image("2022_W4W_Logo-01")}
              alt="wisdom for wisdom program logo"
            />
            <h1>
              HELPING
              <br />
              STUDENTS
              <br />
              SAVE MONEY
              <br />
              FOR COLLEGE
            </h1>

            <div>
              <img
                className="w4w-23__hero--desktop"
                src={image("2023-w4w-hero-image")}
                alt="wisdom for wisdom grid art"
              />

              <img
                className="w4w-23__hero--mobile"
                src={image("2023-hero-image-mobile")}
                alt="wisdom for wisdom grid art"
              />
            </div>
          </div>
        </div>

        <div className="w4w-23__section">
          <div className="w4w-23__container">
            <h2>Saving for College? We Want to Help!</h2>
            <p>
              College costs are higher than ever, and we’re committed to helping
              local families save money.
            </p>

            <p>
              Our 2024 Wisdom For Wisdom program aims to support students by
              offering free wisdom teeth removal procedures. This year, we are
              thrilled to announce our recipient: Dante Scott from Gibbstown,
              NJ!
            </p>

            <p>
              Dante, a student at Gloucester County Institute of Technology in
              Sewell, NJ, dreams of becoming an engineer and plans to attend
              Drexel University. Despite facing significant hardships,
              including the loss of his mother and having a father in prison,
              Dante's determination to pursue his dreams remains unwavering.
              His guardians, who have taken on the responsibility of caring
              for him and his brother, are dedicated to saving for his college
              education. For them, every penny saved is vital.
            </p>
            <p>
              The Wisdom for Wisdom program will help ease some of the financial
              burdens, allowing Dante to focus on his bright future without the
              worry of dental expenses.
            </p>

            <p>
              We are incredibly excited to host this program for our community
              and deeply grateful for all the applications we received.
            </p>

            <h4>
              Stay tuned for updates on Dante’s journey and procedure by
              following us on{" "}
              <a
                href="https://www.facebook.com/westwoodoralsurgeryNJ/"
                target="_blank"
                title="Follow us on Facebook">
                Facebook
              </a>{" "}
              and{" "}
              <a
                href="https://www.instagram.com/westwoodoralsurgeryNJ/"
                target="_blank"
                title="Follow us on Instagram">
                Instagram
              </a>
              !
            </h4>

            <p>
              <em>
                Learn more about{" "}
                <a
                  href="procedures/wisdom-teeth-removal-woodbury-heights-nj/"
                  title="Wisdom Teeth Removal">
                  wisdom teeth removal
                </a>
                .
              </em>
            </p>
          </div>
        </div>

        <div className="w4w-23__section">
          <div className="w4w-23__container">
            <div className="w4w-23__image-container">
              <Image publicId={"WEST/DEV/Dante-w4w-recipient.jpg"} />
            </div>
          </div>
        </div>

        <div className="w4w-23__rounded-container">
          <div className="w4w-23__section">
            <div className="w4w-23__container">
              <div>
                <div>
                  <h3>Just the facts...</h3>
                  <ul>
                    <li>
                      Wisdom teeth are the only teeth that don’t form before
                      birth.
                    </li>
                    <li>
                      85% of wisdom teeth will need to be removed at some point.
                    </li>
                    <li>
                      Wisdom teeth are the last of the 32 adult permanent teeth
                      to develop and erupt.
                    </li>
                    <li>
                      Wisdom teeth that are impacted (meaning they cannot erupt
                      completely) can cause problems in your mouth and sinuses.
                    </li>
                    <li>
                      Wisdom teeth often appear between the ages of 17 and 25.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default W4W
